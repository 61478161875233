.root {
  margin-bottom: 0;
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  border-width: 1px;
  border-style: solid;
  padding-right: 8px;
  padding-left: 8px;
  width: calc(100% - 18px);
  font-size: 10pt;
}
