@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  border-radius: 4px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      /* box-shadow: var(--boxShadowListingCard); */
    }
  }
}

.rootForImage {
  width: 100%;
  height: 100%;

  object-fit: cover;
  background: var(--matterColorLight);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  max-height: 26px;
  height: 100%;
  margin-top: 0.2vh;
  
  @media (--viewportMedium) {
    margin-top:5px;
   }

}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  /* margin-right: 18px; */
}


.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: clamp(3pt, 2.5vw, 10pt);


  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 10pt;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.8vh;

  @media(--viewportMedium){
  height: 2.5vh;

  }
  
}

.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  font-size: clamp(3pt, 2.5vw, 10pt);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 25vw;
  flex-grow: 1;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    font-size: 10pt;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
    height: 40px; /* TODO: This is a hack to make the title fit in the listing card */
    overflow: hidden;
    width: 10vw;
    text-overflow: ellipsis;

  }
}

.authorInfo {
  @apply --marketplaceH3FontStyles;
  font-size: clamp(2pt, 2.2vw, 10pt);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 10pt;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.sizeInfo {
  @apply --marketplaceDefaultFontStyles;
  display: none;

  @media (--viewportMedium) {
    font-size: 10pt;
    font-weight: var(--fontWeightRegular);
    line-height: 20px;
    width: 5vw;
    height: 40px; /* to prevent overflow height is double of line height */
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    text-align: end;
    text-overflow: ellipsis;

  }
}

.authorNameLink {
  text-decoration: none;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.listingLikeIcon {
  position: absolute;
  top: 0;
  right: 4px;

  @media (--viewportMedium){
    top: -2px;

  }
    
}

.heartIcon {
  color: transparent;

  &:hover {
    color: var(--matterColorDark);
  }
}

.heartIconLiked {
  color: var(--matterColorDark);
}

.verifiedBarge {
  padding-left: 5px;
  margin-bottom: 4px;

  @media (--viewportMedium) {
    margin-bottom: 3px;
  }
}