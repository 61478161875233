.root {
  width: 50%;
  display: flex;
  height: 100%;
  outline: none;
  position: relative;

  @media (--viewportMedium) {
  width: auto;

}
}