@import '../../../styles/propertySets.css';

.quantityField {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  /* padding: 0 24px; */
  margin-top: 5px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 5px;
  }
}

.finePrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

#size {
  border-bottom-color: var(--matterColorLight);
  font-size: 10pt;
  font-weight: var(--fontWeightSemiBold);
  min-height: 25px;
  max-height: 30px;
  padding: 0px 0 2px 10px;

  &::placeholder {
    color: var(--matterColor);
  }
}

.sizeField {
  padding: 5px 0 5px 10px;
  width: 100%;
  border-color: #000000;
  border-width: 0px;

  font-size: 12px;

}

.sizeField :hover {
  border-bottom-color: #FFFFFF;
}

.sizeFieldError {
  border-bottom-color: var(--failColor); 
}

.sizeError {
  color: var(--failColor);
  font-size: 10pt;
  display: block;
}

.sizeForm {
  border-color: var(--matterColor);
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  min-height: 30px;
  max-height: 35px;

  border-bottom-color: black;
  border-bottom-width: 1px;
}

.sizeForm,
select#OrderPanelProductOrderForm_size {
  display: block;
}

.contactSellerForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 9px;
}

.contactSeller {
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  width: 47%;
  border-color: var(--matterColor);
  border-width: 1px;
  border-style: solid;
  min-height: 25px;
  max-height: 30px;
  text-align: left;
  padding-left: 10px;

  &:hover {
    background-color: var(--matterColor);
    color: var(--matterColorLight);
  }
}

.sizeNoForm {
  display: block;
}

.sizeValue {
  display: block;
  padding-left: 10px;
  min-height: 25px;
  max-height: 30px;
  font-size: 10pt;
  font-weight: var(--fontWeightSemiBold);
}

.message {
  display: block;
}

.offer {
  display: block;
}

.purchase {
  border-color: var(--matterColor);
  border-width: 1px;
  border-style: solid;
  text-align: left;
  padding-left: 10px;
  min-height: 30px;
  max-height: 35px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--matterColorDark);
    color: var(--matterColorLight);
    border-color: var(--matterColorDark);
  }

  &:disabled {
    background-color: #CCCCCC;
    color: #FFFFFF;
  }
}

.accepting_offer {
  
}