.root {
  padding: 0;
  justify-content: center;
  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    /* margin-left: 0; */
    margin-left: 2px;
  }
  
  @media (--viewportLarge) {
    margin-left: 0 36px;
  }

}
