@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceTabNavFontStyles;
  font-size: clamp(3pt,2.5vw,9pt);

  color: var(--matterColorAnti);
  padding: 0;

  /* push tabs against bottom of tab bar */
  margin-top: 0;

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>"); */
  /* background-position: calc(var(--TabNav_linkWidth) + 4px) center; */ /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>"); */
      /* background-position: right center; */
      color: var(--matterColorDark);
    }

    @media (--viewportLarge) {
      font-size: 20px;
      padding-bottom: 20px;
    }


  }

.selectedLink {
  color: var(--matterColorDark);
  text-decoration: none;
  font-weight: bold;

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    color: var(--matterColorDark);
    /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>"); */
    /* background-position: right center; */
  }

}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
