.root {
}

.select {
  color: var(--matterColorAnti);
  /* border-bottom-color: var(--attentionColor); */
  padding-right: 20px;
  font-size: 8pt;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
  /* & > option:checked {
    background-color: var(--matterColorDark);
    color: var(--matterColorBright);
  } */

  /* & > option li:hover {
    background-color: var(--matterColorDark);
    color: var(--matterColorBright);
  } */
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}